import { useEffect, useState } from 'react';
import {
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword
} from 'firebase/auth'

import { auth } from './firebaseAuth';
import './App.css';
import Login from './components/Login'
import AnnouncementEventsForm from './components/AnnouncementEventForm' 

function App() {

  const [user, setUser] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')



  const clearInputs = () => {
    setEmail = ('')
    setPassword = ('')
  }

  const clearErrors = () => {
    setEmailError('')
    setPasswordError('')
  }

  const handleLogin = async () => {
    try {
      const user = await signInWithEmailAndPassword(auth, email, password)
      console.log(user)

    } catch (err) {

      switch (err.code) {
        case "auth/invalid-email":
          alert("Invalid email")
          break
        case "auth/user-not-found":
          alert("User doesn't exist")
          break
        case "auth/wrong-password":
          alert("Incorrect Password")
          break
      }
    }

  }

  const handleLogout = async () => {
    await signOut(auth)
  }
  const authListener = () => {
    onAuthStateChanged(auth, user => {
      if (user) {
        setUser(user)
      } else {
        setUser('')
      }
    })
  }

  useEffect(() => {
    authListener();
  }, [])

  return (
    <div>

      {user ? <AnnouncementEventsForm 
        handleLogout={handleLogout}
      /> : 

      <Login 
      email={email} 
      setEmail={setEmail} 
      password={password} 
      setPassword={setPassword} 
      emailError={emailError} 
      setEmailError={setEmailError} 
      passwordError={passwordError} 
      setPasswordError={setPasswordError}
      handleLogin={handleLogin}
      />
    }
      {/* {user ? <AnnouncmentEventForms /> : <ShowLogin />} */}



    </div>
  );
}




export default App;
