import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Event from './components/Event';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

var time = document.getElementById("time")
var timeError = document.getElementById('time-error')

time.addEventListener('input', function(e) {

  console.log(e.target.value)
  var regex = /^\b((1[0-2]|0?[1-9]):([0-5][0-9])([AaPp][Mm]))$/
  var currentValue = e.target.value 
  var valid = regex.test(currentValue)


  if(valid){
    timeError.style.display = 'none'
  } else {
    timeError.style.display = 'block'

  }

})
