import { useEffect, useState } from 'react';



import '../App.css'
import Announcement from './Announcement';
import Event from "./Event";


const AnnouncementEventsForm = ( {handleLogout} ) => {

  const [eventAnnouncement, setEventAnnouncement] = useState("event");

  const [eventVisible, setEventVisible] = useState(false);
  const [announcementVisible, setAnnouncementVisible] = useState(false);

  useEffect(() => {
    eventAnnouncement === "event" ? setEventVisible(true) : setEventVisible(false);
    eventAnnouncement === "announcement" ? setAnnouncementVisible(true) : setAnnouncementVisible(false);
  }, [eventAnnouncement]);


  const handleOnChange = (e) => {
    setEventAnnouncement(e.target.value);
  };
  return (


    <div className="form">
      <button onClick={handleLogout} className='signOutButton'>Sign Out</button>

      <div className="">
        <h1>Select Form Type:</h1>
      </div>
      <div className="mt-4">
        <select className="form-select" value={eventAnnouncement} onChange={handleOnChange}>
          <option value="event">Event</option>
          <option value="announcement">Announcement</option>
        </select>
      </div>
      {eventVisible && <Event />}
      {announcementVisible && <Announcement />}
    </div>

  )
}


export default AnnouncementEventsForm;

