import React, { useState } from "react";
import * as yup from 'yup'
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import { addDoc, collection } from "firebase/firestore";
import { v4 as uuid } from "uuid"
import { string } from 'yup';
import {
    getAuth,
    signOut
} from 'firebase/auth'

import storage from "../firebaseConfig.js"
import db from "../firebaseCollection.js";

function Event() {
    // State to store uploaded file


    const [name, setName] = useState();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [time, setTime] = useState("");
    const [date, setDate] = useState("");
    const [publishDate, setPublishDate] = useState("");
    const [location, setLocation] = useState("");
    const [series, setSeries] = useState("");
    const [cost, setCost] = useState("");
    const [eventURL, setEventUrl] = useState("");
    const [imageFile, setImageFile] = useState();
    const [imageURL, setImageURL] = useState("");

    const [setValidName] = useState();
    const [setValidTitle] = useState();
    const [setValidDescription] = useState();
    const [setValidTime] = useState();
    const [setValidDate] = useState();
    const [setValidPublishDate] = useState();
    const [setValidLocation] = useState();

    const [timeInvalid, setTimeInvalid] = useState("")

    const [timeTouched, setTimeTouched] = useState(false)


    const timeIsValid = time !== /^\b((1[0-2]|0?[1-9]):([0-5][0-9])([AaPp][Mm]))$/

    const schema = yup.object().shape({
        time: yup.string().matches(timeIsValid, "Format 04:00pm required.")
    })

    const eventCollectionRef = collection(db, "events")


    // progress
    const [percent, setPercent] = useState(0);



    // Handle file upload event and update state
    const handleChange = (e) => {

        const { id, value } = e.target;

        if (id === "name") {
            setName(value);
            setValidName(
                string()
                    .required
                    .isValidSync(value)
            )
        }
        if (id === "title") {
            setTitle(value)
            setValidTitle(
                string()
                    .required
                    .isValidSync(value)
            )
        }
        if (id === "description") {
            setDescription(value)
            setValidDescription(
                string()
                    .required
                    .isValidSync(value)
            )
        }
        if (id === "time") {
            setTime(value)
            // setValidTime(
            //     string()
            //     .required
            //     .isValidSync(value)
            // )

        }
        if (id === "date") {
            setDate(value)
            setValidDate(
                string()
                    .required
                    .isValidSync(value)
            )
        }
        if (id === "publishDate") {
            setPublishDate(value)
            setValidPublishDate(
                string()
                    .required
                    .isValidSync(value)
            )
        }
        if (id === "location") {
            setLocation(value)
            setValidLocation(
                string()
                    .required
                    .isValidSync(value)
            )
        }

        if (id === "series") {
            setSeries(value)
        }

        if (id === "cost") {
            setCost(value)
        }
        if (id === "eventURL") {
            setEventUrl(value)
        }

        if (id === "imageFile") {
            setImageFile(e.target.files[0]);

        }
    }

    const handleUpload = (e) => {

        e.preventDefault();

        if (!name) {
            alert('Missing name');
            return;
        }
        if (!title) {
            alert('Missing title');
            return;
        }
        if (!description) {
            alert('Missing description');
            return;
        }
        if (!time) {
            alert('Missing time');
            return;
        }
        if (!date) {
            alert('Missing date');
            return;
        }
        if (!publishDate) {
            alert('Missing publish date');
            return;
        }
        if (!location) {
            alert('Missing location');
            return;
        }
        if (!imageFile) {
            alert('Missing image');
            return;
        }

        const storageRef = ref(storage, `/images/${uuid()}${imageFile.name}`);

        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, imageFile);

        //name.current.value = ''
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );

                // update progress
                setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    console.log(url);
                    alert('Event Created')

                    addDoc(eventCollectionRef, {
                        name: name,
                        title: title,
                        description: description,
                        time: time,
                        date: date,
                        publishDate: publishDate,
                        location: location,
                        series: series,
                        cost: cost,
                        eventURL: eventURL,
                        imageURL: url
                    })



                });
            }

        );



    };



    return (
        <div>

            <div className="form-class">


                <div className="form-body">
                    <div className="username">
                        <label>Created By*
                            <div className="form-input">
                                <input
                                    className="form_label"
                                    type="text"
                                    required
                                    placeholder="Name"
                                    id="name"
                                    value={name}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </label>
                    </div>
                    <div>
                        <label>Event Title*
                            <div className="form-input">
                                <input
                                    className="form_label"
                                    type="text"
                                    required
                                    placeholder="Title"
                                    id="title"
                                    value={title}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </label>
                    </div>

                    <div>
                        <label>Description*
                            <div className="form-input">
                                <input
                                    className="form_label"
                                    type="text"
                                    required
                                    placeholder="Description"
                                    id="description"
                                    value={description}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </label>
                    </div>

                    <div>
                        <label>Event Time*
                            <div className="form-input">
                                <input
                                    className="form_label"
                                    type="text"
                                    required
                                    placeholder="04:00pm"
                                    id="time"
                                    value={time}
                                    onChange={(e) => handleChange(e)}
                                    // onBlur={() => setTimeTouched(true)}

                                />
                                <p id="time-error">Time format must be 04:00pm</p>

                            </div>
                        </label>
                    </div>

                    <div>
                        <label>Event Date*
                            <div className="form-input">
                                <input
                                    className="form_label"
                                    type="date"
                                    required
                                    id="date"
                                    value={date}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </label>
                    </div>

                    <div>
                        <label>Publish Date*
                            <div className="form-input">
                                <input
                                    className="form_label"
                                    type="date"
                                    required
                                    id="publishDate"
                                    value={publishDate}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </label>
                    </div>

                    <div>
                        <label>Location*
                            <div className="form-input">
                                <input
                                    className="form_label"
                                    type="text"
                                    placeholder="location"
                                    required
                                    id="location"
                                    value={location}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </label>
                    </div>


                    <div>
                        <label>Series
                            <div className="form-input">
                                <input
                                    className="form_label"
                                    type="text"
                                    required
                                    placeholder="Series"
                                    id="series"
                                    value={series}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </label>
                    </div>

                    <div>
                        <label>Cost
                            <div className="form-input">
                                <input
                                    className="form_label"
                                    type="text"
                                    required
                                    placeholder="$100"
                                    id="cost"
                                    value={cost}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </label>
                    </div>

                    <div>
                        <label>Register URL
                            <div className="form-input">
                                <input
                                    className="form_label"
                                    type="text"
                                    required
                                    placeholder="http://www.ccffsu.org"
                                    id="eventURL"
                                    value={eventURL}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </label>
                    </div>
                    <div>
                        <label> Upload File
                            <div>
                                <input
                                    className="image-upload"
                                    type="file"
                                    id="imageFile"
                                    onChange={(e) => handleChange(e)}
                                    accept="/image/*" />

                                Uploading: {percent}%
                            </div>
                        </label>
                    </div>
                    <div>
                        <button
                            className="form-submit-button"
                            onClick={handleUpload}>Create Event</button>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>

    );

};


export default Event;