import React, { useState } from "react";
 
import { addDoc } from "firebase/firestore";
import { collection } from 'firebase/firestore'
import db from "../firebaseCollection.js";



function Announcement() {


    const [headline, setHeadline] = useState("");
    const [createdBy, setCreatedBy] = useState("");
    const [date, setDate] = useState("");
    const [publishDate, setPublishDate] = useState("");
    const [description, setDescription] = useState("");
    const [url, setUrl] = useState("");


    const announcementCollectionRef = collection(db, 'announcements');


    const handleChange = (e) => {

 
        const {id, value} = e.target;
        if (id === "headline") {
            setHeadline(value)
        }
        if (id === "createdBy") {
            setCreatedBy(value)
        }
        if (id === "date") {
            setDate(value)
        }
        if (id === "publishDate") {
            setPublishDate(value)
        }
        if (id === "description") {
            setDescription(value)
        }
        if (id === "url") {
            setUrl(value)
        }

    };

    const handleSubmit = async (e) => {

        e.preventDefault()

        if (!headline) {
            alert('Missing headline')
            return
        }

        if (!date) {
            alert('Missing date')
            return
        }

        if (!publishDate) {
            alert('Missing Publish Date')
            return
        }

        if (!description) {
            alert('Missing Description')
            return
        }


        try {
            await addDoc(announcementCollectionRef, {
                headline: headline,
                createdBy: createdBy,
                date: date,
                publishDate: publishDate,
                description: description,
                url: url
            });

            console.log(headline);
            alert('Announcement Created')
        } catch(err) {
            alert(err)
        }




    };

    return (
        <form>
            <div className="form-class">
                <div className="form-body">
                    <div className="headline">
                        <label>Headline*
                        <div className="form-input">
                            <input
                            className="form_label"
                            type="text"
                            required
                            placeholder="Name"
                            id="headline"
                            value={headline}
                            onChange = {(e) => handleChange(e)}

                            />
                            </div>
                        </label>
                    </div>

                    <div>
                        <label>Created By*
                        <div className="form-input">
                            <input
                                className="form_label"
                                type="text"
                                placeholder="Name"
                                id="createdBy"
                                value={createdBy}
                                onChange={(e) => handleChange(e)}
                            />
                            </div>
                        </label>
                    </div>

                    <div>
                        <label>Date*
                        <div className="form-input">
                            <input
                                className="form_label"
                                type="date"
                                id="date"
                                value={date}
                                onChange={(e) => handleChange(e)}
                            />
                            </div>
                        </label>

                    </div>

                    <div>
                        <label>Publish Date*
                        <div className="form-input">
                            <input
                                className="form_label"
                                type="date"
                                id="publishDate"
                                value={publishDate}
                                onChange={(e) => handleChange(e)}
                            />
                            </div>
                        </label>

                    </div>

                    <div>
                        <label>Description*
                        <div className="form-input">
                            <input
                                className="form_label"
                                placeholder="description"
                                type="text"
                                id="description"
                                value={description}
                                onChange={(e) => handleChange(e)}
                            />
                            </div>
                        </label>
                    </div>

                    <div>
                        <label>URL
                        <div className="form-input">
                            <input
                                className="form_label"
                                placeholder="http://www.ccffsu.com"
                                type="text"
                                id="url"
                                value={url}
                                onChange={(e) => handleChange(e)}
                            />
                            </div>
                        </label>
                    </div>
                    <div>
                        <button onClick={handleSubmit}>Create Announcement</button>
                    </div>
                </div>
            </div>
        </form>

    );
};

export default Announcement;