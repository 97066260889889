import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";


const app = initializeApp ({
    apiKey: "AIzaSyAJacXPXpUM6lhdkSL6qCoNB_QmC2Rku-Y",
    authDomain: "ccfapp-a7693.firebaseapp.com",
    projectId: "ccfapp-a7693",
    storageBucket: "ccfapp-a7693.appspot.com",
    messagingSenderId: "601822913710",
    appId: "1:601822913710:web:10d274b9609d1a4b40f93f",
    measurementId: "G-3F0RBQ1DL5"
  });

  const storage = getStorage(app)

  export default storage ; 